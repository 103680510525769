<template>
  <home-base-card
    card_title="媒体管理"
    :link_path="link_path"
    icon="mdi-web"
    :loading="loadingFlg"
    :set_color="siteCount > 0 ? 'bg_ok' : 'bg_ng'"
    :is_error="isError"
  >
    <div>
      <span class="text-h5 text-blue mr-1">{{ siteCount }}</span
      >件 登録されています
    </div>
  </home-base-card>
</template>
<script>
import { reactive, toRefs, onMounted } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import retryRequest from "@/common/retryRequest";

export default {
  props: ["link_path"],
  setup(props, ctx) {
    const ShopSitesRepository = repositoryFactory.get("shopSites");
    const state = reactive({
      loadingFlg: false,
      siteCount: 0,
      isError: false,
    });

    // 更新サイトを一覧に反映する
    const setShopSiteList = async () => {
      // // 更新サイト一覧取得
      // const array = await store.getters["shop_sites/getShopSiteList"];
      // state.siteCount = array.length;

      state.isError = false;
      state.loadingFlg = true;

      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      try {
        const response = await retryRequest(ShopSitesRepository.list, params);
        if (response.data) {
          state.siteCount = response.data.length;
        }
      } catch (error) {
        state.isError = true;
      } finally {
        state.loadingFlg = false;
      }
    };

    onMounted(() => {
      setShopSiteList();
    });

    return {
      props,
      ...toRefs(state),
    };
  },
};
</script>
