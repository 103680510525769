<template>
  <home-base-card
    card_title="勤務ステータス更新"
    :link_path="link_path"
    icon="mdi-account-clock"
    :loading="loadingFlg"
    :set_color="isAuto ? 'bg_ok' : 'bg_ng'"
    :is_error="isError"
  >
    <div>
      <span v-if="!isAuto" class="text-h5">更新停止中</span>
      <span v-else>
        <span class="text-h5 text-blue mr-1">自動更新中</span>
        <span>
          <span class="mr-2">
            待機<span class="text-h5 ml-1 mr-1">{{ waitingCount }}</span
            >名</span
          >
          <span>
            接客<span class="text-h5 text-blue ml-1 mr-1">{{
              workingCount
            }}</span
            >名</span
          ></span
        >
      </span>
    </div>
  </home-base-card>
</template>

<script>
import { reactive, toRefs, onMounted } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import retryRequest from "@/common/retryRequest";

export default {
  props: ["link_path"],
  setup(props, ctx) {
    const WorkingCastsRepository = repositoryFactory.get("workingCasts");
    const WorkingShopsRepository = repositoryFactory.get("workingShops");

    const state = reactive({
      loadingFlg: false,
      isAuto: false,
      workingCount: 0,
      waitingCount: 0,
      isError: false,
    });

    const getStatus = async () => {
      state.isError = false;
      state.loadingFlg = true;

      state.isAuto = false;
      state.workingCount = 0;
      state.waitingCount = 0;

      const shop_id = store.getters["shops/currentShop"].id;

      // 自動更新中かどうか
      try {
        const response = await retryRequest(
          WorkingShopsRepository.get_by_shopid,
          shop_id
        );
        if (response.data) {
          state.isAuto = response.data.is_auto;
        }
      } catch (error) {
        state.isError = true;
        state.loadingFlg = false;
        return;
      }

      // 勤務ステータスの取得
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        work_date: utilDate.getDateFormatLong(utilDate.getToday()),
      };
      try {
        const response = await retryRequest(
          WorkingCastsRepository.list,
          params
        );
        if (response.data) {
          Object.keys(response.data).forEach(function (key) {
            const rd = response.data[key];
            if (rd.work_status === setting.work_status_working) {
              if (rd.mode === setting.working_mode_standby) {
                state.waitingCount++;
              } else if (rd.mode === setting.working_mode_service) {
                state.workingCount++;
              }
            }
          });
        }
      } catch (error) {
        state.isError = true;
      } finally {
        state.loadingFlg = false;
      }
    };

    onMounted(() => {
      getStatus();
    });

    return {
      props,
      ...toRefs(state),
    };
  },
};
</script>
