import setting from "@/common/setting.js";

// 共通で使用する日付関連の定義
const utilTime = {
  // getTimeFormat
  // 引数：d=日付型
  // 戻り値："HH:MI"の文字列を返却
  getTimeFormat(d) {
    if (!d) return null;
    return (
      ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)
    );
  },
};

export default utilTime;
