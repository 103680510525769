import setting from "./setting";

const retryRequest = async (requestFunction, params, isAlert = false) => {
  const retryCount = setting.axios_retry_count;
  let attempts = 0;
  let response = null;
  while (attempts < retryCount) {
    try {
      response = await requestFunction(params, { "X-Retrying": "true" });
      if (response.data) {
        break; // 成功した場合はループを抜ける
      }
    } catch (error) {
      attempts++;
      console.log(`attempts=${attempts}`);
      if (attempts >= retryCount) {
        console.error(
          `ERROR: ${requestFunction.name} (${error}) retry:${attempts}`
        );
        if (isAlert) {
          alert("サーバにて正しく処理できませんでした。再度実行してください。");
        }
        throw error; // リトライ回数を超えた場合はエラーをスロー
      }
    }
  }
  return response;
};

export default retryRequest;
