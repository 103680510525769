<template>
  <home-base-card
    card_title="スケジュール管理"
    :link_path="link_path"
    icon="mdi-calendar-month"
    :loading="loadingFlg"
    :set_color="todayCount > 0 ? 'bg_ok' : 'bg_ng'"
    :is_error="isError"
  >
    <div>
      本日の出勤予定<span class="text-h5 text-blue ml-1 mr-1">{{
        todayCount
      }}</span
      >名
    </div>
  </home-base-card>
</template>

<script>
import { reactive, toRefs, onMounted } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import utilDate from "@/common/utilDate.js";
import setting from "@/common/setting.js";
import retryRequest from "@/common/retryRequest";

export default {
  props: ["link_path"],
  setup(props, ctx) {
    const SchedulesRepository = repositoryFactory.get("schedules");

    const state = reactive({
      loadingFlg: false,
      todayCount: 0,
      isError: false,
    });

    const getSchedule = async () => {
      state.isError = false;
      state.loadingFlg = true;
      state.todayCount = 0;

      const shop_dateline = store.getters["shops/dateline"];
      // 当日分の日付を取得
      const today = utilDate.getToday(0, shop_dateline);
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        start_date: utilDate.getDateFormatLong(today),
        end_date: utilDate.getDateFormatLong(today),
      };
      try {
        const response = await retryRequest(SchedulesRepository.list, params);
        if (response.data) {
          Object.keys(response.data).forEach(function (key) {
            if (
              response.data[key].start_time !== null &&
              response.data[key].end_time !== null
            ) {
              state.todayCount += 1;
            }
          });
        }
      } catch (error) {
        state.isError = true;
      } finally {
        state.loadingFlg = false;
      }
    };

    onMounted(() => {
      getSchedule();
    });

    return {
      props,
      ...toRefs(state),
    };
  },
};
</script>
