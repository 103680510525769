<template>
  <home-base-card
    card_title="更新コンテンツ"
    :link_path="link_path"
    icon="mdi-update"
    :loading="loadingFlg"
    :set_color="updateCount > 0 ? 'bg_ok' : 'bg_ng'"
    :is_error="isError"
  >
    <div>
      <span class="text-h5 mr-1">{{ contentCount }}</span
      >件中、 <span class="text-h5 text-blue mr-1">{{ updateCount }}</span
      >件が自動更新中
    </div>
  </home-base-card>
</template>
<script>
import { reactive, toRefs, onMounted } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import retryRequest from "@/common/retryRequest";

export default {
  props: ["link_path"],
  setup(props, ctx) {
    const updateContentsRepository = repositoryFactory.get("updateContents");
    const state = reactive({
      loadingFlg: false,
      updateCount: 0,
      contentCount: 0,
      isError: false,
    });

    const getTemplateList = async () => {
      state.isError = false;
      state.loadingFlg = true;

      try {
        const response = await retryRequest(
          updateContentsRepository.list,
          store.getters["shops/currentShop"].id
        );
        if (response.data) {
          state.contentCount = response.data.length;
          Object.keys(response.data).forEach(function (key) {
            state.updateCount += response.data[key].is_auto ? 1 : 0;
          });
        }
      } catch (error) {
        state.isError = true;
      } finally {
        state.loadingFlg = false;
      }
    };

    onMounted(() => {
      getTemplateList();
    });

    return {
      props,
      ...toRefs(state),
    };
  },
};
</script>
